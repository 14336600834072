import React from "react";
import Compu1 from "../../../assets/image/Compu1.png";
import Compu2 from "../../../assets/image/Compu2.png";
import glaces from "../../../assets/image/glaces.png";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import head from "../../../assets/image/head.png";
import { useMotionValue, useTransform, motion } from "framer-motion";
import SmokeLight from "@atoms/SmokeLight";
import styles from "./monitor.module.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useOnScreen } from "../../../hooks/useOnScreen";

const hide = {
  opacity: 0,
  y: 20,
};

const show = {
  opacity: 1,
  y: 0,
};

const transition = {
  type: "spring",
  stiffness: 20,
};

const greenSmoke =
  "radial-gradient(39.91% 42.1% at 46.34% 47.34%, rgba(4, 230, 167, 0.25) 0%, rgba(4, 6, 20, 0) 100%)";

const smokeBlue =
  "radial-gradient(39.91% 42.1% at 46.34% 47.34%, rgba(16, 140, 255, 0.2) 0%, rgba(4, 6, 20, 0) 100%)";

const Monitor = () => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoint();
  const [monitorRef, isMonitorVisible] = useOnScreen({ threshold: 0.1 });
  const angle = 10;
  const perspective = 3000;
  const y = useMotionValue(0.5);
  const x = useMotionValue(0.5);

  const rotateY = useTransform(x, [0, 1], [-angle, angle], {
    clamp: true,
  });

  const onMove = e => {
    // get position information for the card
    const bounds = e.currentTarget.getBoundingClientRect();

    // set x,y local coordinates
    const xValue = (e.clientX - bounds.x) / e.currentTarget.clientWidth;
    const yValue = (e.clientY - bounds.y) / e.currentTarget.clientHeight;

    // update MotionValues
    x.set(xValue, true);
    y.set(yValue, true);
  };
  return (
    <section
      id='monitor'
      ref={monitorRef}
      className={`${styles.section} text-center ${styles.person}`}
    >
      <div className={styles.leftSmoke}>
        <SmokeLight background={smokeBlue} width={2000} height={700} />
      </div>
      <div className={styles.rightSmoke}>
        <SmokeLight background={greenSmoke} width={1400} height={700} />
      </div>
      <motion.h2
        initial={hide}
        animate={isMonitorVisible ? show : hide}
        transition={transition}
        className={styles.title}
      >
        {t("SERVICES.MONITOR")}
      </motion.h2>
      <motion.p
        initial={hide}
        animate={isMonitorVisible ? show : hide}
        transition={{ ...transition, delay: 0.3 }}
        className={styles.description}
      >
        {t("SERVICES.MONITORSUB")}
      </motion.p>

      <div
        style={{
          marginTop: breakpoints.sm ? 120 : 220,
          display: "flex",
          justifyContent: "center",
          position: "relative",
          transform: breakpoints.sm ? "scale(0.7)" : "none",
        }}
      >
        <img
          src={Compu1}
          style={{ margin: 0, marginRight: 10 }}
          alt='N5 Laptop'
          loading="lazy"
        />
        <img src={Compu2} style={{ margin: 0, marginLeft: 10 }}  loading="lazy" alt='Metrics' />

        <motion.div
          className={styles.animationContainer}
          style={{ perspective }}
        >
          <motion.img
            src={glaces}
            className={styles.animationContainer_Glasses}
            style={{ y: 250, x: rotateY, rotateY }}
            alt='Anteojos'
            loading="lazy"
          />
          <motion.img
            src={head}
            className={styles.animationContainer_Head}
            onPointerMove={onMove}
            style={{ rotateY }}
            alt='Cabeza'
            loading="lazy"
          />
        </motion.div>
      </div>
    </section>
  );
};

export default Monitor;
